import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Supabase from "../../models/Supabase";
import Button from "@mui/material/Button";

const SuccessLogin = (props) => {
  const navigate = useLocation();
  const { state } = navigate;
  const supabase = props.supabase;

  const [disableResend, setDisableResend] = useState(false);
  if (!state.email) {
    return navigate("/login");
  }

  return (
    <div>
      <h1>Success Login</h1>
      <p>
        Welcome <a href={state?.email}>{state?.email}</a>, please verify your
        email to continue
      </p>
      <Button
        variant="outlined"
        onClick={() =>
          // add persistent storage for time to limit the number of requests
          {
            const lastRequestTime = localStorage.getItem("lastRequestTime");
            const currentTime = new Date().getTime();

            if (lastRequestTime && currentTime - lastRequestTime < 60000) {
              alert("Please wait a minute before requesting again.");
              return;
            }

            localStorage.setItem("lastRequestTime", currentTime);
            supabase.resendConfirmationEmail(state?.email);
          }
        }
      >
        Resend confirmation email
      </Button>
    </div>
  );
};

export default SuccessLogin;
