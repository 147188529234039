export default class PyServer {
  // set a constructor
  constructor() {
    this.url = process.env.REACT_APP_PYTHON_URL;
  }

  async sendInvite(eventId, user_id) {
    // Send the data to the server using fetch
    try {
      const result = await fetch(`${this.url}/sendInvite/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ event_id: eventId, user_id: user_id }),
      });
      if (!result.ok) {
        console.log("error: ", result);
        return { error: result };
      }
      const data = await result.text();
      console.log("result: ", data);
      return { data: data };
    } catch (error) {
      console.log(error);
      return { error: error };
    }
  }

  getData(endpoint) {
    // Send the data to the server using fetch
    fetch(`${this.url}/${endpoint}`)
      .then((response) => response.json())
      .then((responseData) => {
        // Handle the response from the server
        console.log(responseData);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error:", error);
      });
  }

  sendData(data, endpoint) {
    // Convert the data to JSON
    const jsonData = JSON.stringify(data);
    const url = `${this.url}/${endpoint}`;

    // Send the data to the server using fetch
    fetch(
      { url },
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: jsonData,
      }
    )
      .then((response) => response.json())
      .then((responseData) => {
        // Handle the response from the server
        console.log(responseData);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error:", error);
      });
  }
}
