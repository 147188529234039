import React, { useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import Supabase from "../../models/Supabase";
import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";
const AddParticipant = (props) => {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const eventId = props.eventId;
  const supabase = props.supabase;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let { data, error } = await supabase.addParticipant(email, firstName);
    if (error) {
      console.error("Error adding participant: ", error);
      return;
    }
    const participant = data[0];
    const participantId = data[0].id;
    ({ data, error } = await supabase.addParticipantToEvent(
      eventId,
      participantId
    ));
    setLoading(false);
    if (error) {
      console.error("Error adding participant to event: ", error);
      return;
    }
    setFirstName("");
    setEmail("");
    console.log("participant: ", participant);
    props.addParticipantToEvent(participant);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <TextField
            type="text"
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            type="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            // make height match the text field
            style={{ height: "55px", width: "150px" }}
            loading={loading}
            loadingPosition="start"
          >
            Submit
            <SendIcon style={{ marginLeft: "10px" }} />
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddParticipant;
