import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import Supabase from "../../models/Supabase";
import { Grid } from "@mui/material";
import { FormControl } from "@mui/material";
export default function EmailSignup(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const supabase = props.supabase;
  const signup = async (e) => {
    try {
      const isEmailValid = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
        email
      );
      if (!isEmailValid) {
        return;
      }
      const result = await supabase.signup(email, password);
      navigate("/successLogin", { state: { email: email } });
    } catch (error) {
      console.error("Error fetching data: ", error);
      throw new Error(error);
    }
  };

  return (
    <div className="container" style={{ padding: "50px 0 100px 0" }}>
      <div>
        <h1>Sign up</h1>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            signup(e);
          }}
        >
          <FormControl>
            <TextField
              required
              label="email"
              margin="normal"
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              value={email}
              error={
                email.length > 0 &&
                !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)
              }
              helperText={
                email.length > 0 &&
                !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)
                  ? "Invalid email"
                  : ""
              }
            />
            <TextField
              id="outlined-adornment-password"
              required
              type={showPassword ? "text" : "password"}
              margin="normal"
              label="Password"
              onChange={(e) => setPassword(e.target.value)}
              //   create error if password is less than 8 characters
              error={password.length > 0 && password.length < 6}
              helperText={
                password.length > 0 && password.length < 6
                  ? "Password must be at least 6 characters"
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <></>
                  // <InputAdornment position="end">
                  //   {/* <IconButton
                  //   aria-label="toggle password visibility"
                  //   //   onClick={handleClickShowPassword}
                  //   //   onMouseDown={handleMouseDownPassword}
                  //   edge="end"
                  // >
                  //   {showPassword ? <VisibilityOff /> : <Visibility />}
                  // </IconButton> */}
                  // </InputAdornment>
                ),
              }}
            />

            <Button
              variant="contained"
              type="submit"
              disabled={email === "" || password === "" || loading}
            >
              Log In
            </Button>
          </FormControl>
        </form>
        <p>{error}</p>
      </div>
    </div>
  );
}
