import { createClient } from "@supabase/supabase-js";
import { insert } from "formik";

class Supabase {
  constructor() {
    this.supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
    this.supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
    this.supabaseClient = createClient(this.supabaseUrl, this.supabaseKey);
  }

  async insertEvent(event) {
    try {
      // get user from local storage
      const user = localStorage.getItem("user");
      // to object
      const userObj = JSON.parse(user);
      console.log("supabase - insertEvent - user: ", userObj);
      // add user id to data
      event.owner = userObj.id;
      const { data, error } = await this.insertRowToTable("events", "*", event);
      console.log("supabase - insertEvent - data: ", data);
      if (error) {
        console.error("Error fetching data: ", error);
        throw new Error(error);
      }
      return { data };
    } catch (error) {
      console.error("Error fetching data: ", error);
      throw new Error(error);
    }
  }

  async insertRowToTable(tableName, select = "*", row) {
    try {
      let { data, error } = await this.supabaseClient
        .from(tableName)
        .insert(row)
        .select(select);
      console.log("supabase - insertDataToTable - data: ", data);
      if (error) {
        console.error("Error fetching data: ", error);
        throw new Error(error);
      }
      return { data };
    } catch (error) {
      console.error("Error fetching data: ", error);
      throw new Error(error);
    }
  }

  async getEvent(eventId) {
    try {
      const { data, error } = await this.supabaseClient
        .from("events")
        .select("*")
        // add join to get participants from table event_user then user on users table
        .select("*")

        .eq("id", eventId)
        .single();

      console.log("supabase - getEvent - data: ", data);
      if (error) {
        console.error("Error fetching data: ", error);
        return { error: error };
      }
      return { data: data };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  async getParticipantParticipate(eventId, userId) {
    try {
      const { data, error } = await this.supabaseClient
        .from("events_users")
        .select("participate")
        .eq("event_id", eventId)
        .eq("user_id", userId)
        .single();

      console.log("supabase - getParticipantParticipate - data: ", data);
      if (error) {
        console.error("Error fetching data: ", error);
        return { error: error };
      }
      return { data: data.participate };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  // add create participant if not exists
  async createUser(participant) {
    try {
      const { data, error } = await this.supabaseClient.insertRowToTable(
        "users",
        "*",
        {
          email: participant.email,
          password: "password",
          email_confirm: false,
        }
      );
      if (error) {
        console.error("Error fetching data: ", error);
        return { error: error };
      }
      return { data: data };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  async addParticipant(email, firstName) {
    try {
      const { data, error } = await this.insertRowToTable("users", "*", {
        email: email,
        first_name: firstName,
      });
      console.log("supabase - addParticipant - data: ", data);
      if (error) {
        console.error("Error fetching data: ", error);
        return { error: error };
      }
      return { data: data };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  async addParticipantToEvent(eventId, participantId) {
    try {
      const { data, error } = await this.insertRowToTable("events_users", "*", {
        event_id: eventId,
        user_id: participantId,
      });
      console.log("supabase - addParticipantToEvent - data: ", data);
      if (error) {
        console.error("Error fetching data: ", error);
        return { error: error };
      }
      return { data: data };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  async getEventParticipants(eventId) {
    try {
      const { data, error } = await this.supabaseClient
        .from("events_users")
        .select("*, users(*)")
        .eq("event_id", eventId)
        .neq("disabled", true);

      console.log("supabase - getEventParticipants - data: ", data);
      if (error) {
        console.error("Error fetching data: ", error);
        return { error: error };
      }
      return { data: data };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  async getEventParticipant(eventId, userId) {
    try {
      const { data, error } = await this.supabaseClient
        .from("events_users")
        .select("*, users(*)")
        .eq("event_id", eventId)
        .eq("user_id", userId)
        .single();
      console.log("supabase - getEventParticipant - data: ", data);
      if (error) {
        console.error("Error fetching data: ", error);
        return { error: error };
      }
      return { data: data };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  async getCountEventParticipant(eventId, email) {
    try {
      const { data, error } = await this.supabaseClient
        .from("events_users")
        .select("*,user_id:users(*)")
        .filter("users.email", "eq", email);

      // const { data, error } = await this.supabaseClient
      //   .from("users")
      //   .select("*,users_id:events_users(*)")
      //   .eq("event_id", eventId)
      //   .filter("email", "eq", email)
      //   .filter("id", "eq", "user_id");

      console.log("supabase - getEventParticipant - data: ", data);
      if (error) {
        console.error("Error fetching data: ", error);
        return { error: error };
      }
      return { data: data };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  async validateInvitation(invitationId, email) {
    try {
      const { data, error } = await this.supabaseClient.rpc(
        "validate_user_invitation",
        {
          function_invitation_id: invitationId,
          function_email: email,
        }
      );

      console.log("supabase - validateUserEvent - data: ", data);
      if (error) {
        console.error("Error fetching data: ", error);
        return { error: error };
      }
      return { data: data };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  async deleteUserData(userId) {
    try {
      const { data, error } = await this.supabaseClient.rpc(
        "delete_user_data",
        {
          function_user_id: userId,
        }
      );

      console.log("supabase - delete user data - data: ", data);
      if (error) {
        console.error("Error fetching data: ", error);
        return { error: error };
      }
      return { data: data };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  async setInvite(userId, eventId) {
    try {
      console.log("supabase - setInvite - userId: ", userId);
      console.log("supabase - setInvite - eventId: ", eventId);
      const { data, error } = await this.supabaseClient
        .from("invitations")
        .insert([{ event_id: eventId, user_id: userId }]);

      console.log("supabase - setInvite - data: ", data);
      if (error) {
        console.error("Error fetching data: ", error);
        return { error: error };
      }
      return { data: data };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  async getUserFromEmail(email) {
    try {
      let { data, error } = await this.supabaseClient
        .from("users")
        .select("id")
        .eq("email", email);
      console.log("supabase - getUserIdFromEmail - data: ", data);
      // get lastest element from data
      data = data[data.length - 1].id;

      console.log("supabase - getUserIdFromEmail - data: ", data);
      if (error) {
        console.error("Error fetching data: ", error);
        return { error: error };
      }
      return { data: data };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  async getInvitation(userId) {
    try {
      const { data, error } = await this.supabaseClient
        .from("invitations")
        .select("*")
        .eq("user_id", userId);
      if (error) {
        console.error("Error fetching data: ", error);
        return { error: error };
      }
      console.log("supabase - getting invitation - data: ", data);
      return { data: data };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  async deleteInvitation(userId) {
    try {
      const { data, error } = await this.supabaseClient
        .from("invitations")
        .delete()
        .in("user_id", [userId])
        .select();
      if (error) {
        console.error("Error fetching data: ", error);
        return { error: error };
      }
      console.log("supabase - deleteInvitation - data: ", data);
      return { data: data };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  async deleteParticipant(userId) {
    console.log("supabase - deleteParticipant - userId: ", userId);
    try {
      let { data: invitationData, error: invitationError } =
        this.deleteInvitation(userId);
      if (invitationError) {
        console.error("Error fetching data: ", invitationError);
        return { error: invitationError };
      }
      console.log("supabase - deleteInvitation - data: ", invitationData);
      let { data: eventData, error: eventError } = await this.supabaseClient
        .from("events_users")
        .delete()
        .eq("user_id", userId)
        .select();

      if (eventError) {
        console.error("Error fetching data: ", eventError);
        return { error: eventError };
      }
      console.log("supabase - deleteParticipant - data: ", eventData);
      return { data: eventData };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  async disableInvitation(userId) {
    try {
      const { data, error } = await this.supabaseClient
        .from("invitations")
        .update({ disabled: true })
        .eq("user_id", userId)
        .select();
      if (error) {
        console.error("Error fetching data: ", error);
        return { error: error };
      }
      console.log("supabase - disableInvitation - data: ", data);
      return { data: data };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  async disableParticipant(userId) {
    console.log("supabase - disableParticipant - userId: ", userId);
    try {
      let { data: invitationData, error: invitationError } =
        this.deleteInvitation(userId);
      if (invitationError) {
        console.error("Error fetching data: ", invitationError);
        return { error: invitationError };
      }
      console.log("supabase - disableInvitation - data: ", invitationData);
      let { data: eventData, error: eventError } = await this.supabaseClient
        .from("events_users")
        .update({ disabled: true })
        .eq("user_id", userId)
        .select();

      if (eventError) {
        console.error("Error fetching data: ", eventError);
        return { error: eventError };
      }
      console.log("supabase - disableParticipant - data: ", eventData);
      return { data: eventData };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  async setEmailSent(userId, eventId) {
    try {
      const { data, error } = await this.supabaseClient
        .from("events_users")
        .update({ email_sent: true })
        .eq("user_id", userId)
        .eq("event_id", eventId)
        .select();
      if (error) {
        console.error("Error fetching data: ", error);
        return { error: error };
      }
      console.log("supabase - setEmailSent - data: ", data);
      return { data: data };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  async optInEvent(eventId, userId) {
    try {
      const { data, error } = await this.supabaseClient
        .from("events_users")
        .update({ participate: true })
        .eq("event_id", eventId)
        .eq("user_id", userId);

      if (error) {
        console.error("Error fetching data: ", error);
        return { error: error };
      }
      console.log("supabase - optInEvent - data: succesfull");
      return { data: "succesfull" };
    } catch (error) {
      console.error("Error optIn ", error);
      return { error: error };
    }
  }

  async optOutEvent(eventId, userId) {
    try {
      const { data, error } = await this.supabaseClient
        .from("events_users")
        .update({ participate: false })
        .eq("event_id", eventId)
        .eq("user_id", userId);

      if (error) {
        console.error("Error fetching data: ", error);
        return { error: error };
      }
      console.log("supabase - optOutEvent - data: ", data);
      return { data: data };
    } catch (error) {
      console.error("Error optOut ", error);
      return { error: error };
    }
  }

  async getDataFromTable(tableName, select = "*", where = "1==1") {
    try {
      let { data, error } = await this.supabaseClient
        .from(tableName)
        .select(select);
      // .where(where);
      console.log("supabase - getDataFromTable - data: ", data);
      if (error) {
        console.error("Error fetching data: ", error);
        throw new Error(error);
      }
      return { data };
    } catch (error) {
      console.error("Error fetching data: ", error);
      throw new Error(error);
    }
  }

  async signup(email, password) {
    try {
      const { user, session, error } = await this.supabaseClient.auth.signUp({
        email: email,
        password: password,
      });
      console.log("supabase - signup - user: ", user);
      console.log("supabase - signup - session: ", session);
      console.log("supabase - signup - error: ", error);
      if (error) {
        console.error("Error fetching data: ", error);
        throw new Error(error);
      }
      return { user, session };
    } catch (error) {
      console.error("Error fetching data: ", error);
      throw new Error(error);
    }
  }

  async login(email, password) {
    try {
      const { data, error } = await this.supabaseClient.auth.signInWithPassword(
        {
          email: email,
          password: password,
        }
      );

      console.log("supabase - login - user data: ", data);
      if (error) {
        console.error("Error fetching data: ", error);
        return { error: error };
      }
      return { data: data };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  async logout() {
    try {
      const { error } = await this.supabaseClient.auth.signOut();
      if (error) {
        console.error("Error fetching data: ", error);
        return { error: error };
      }
      return { data: "Logged out" };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  async getConnectedUser() {
    try {
      const {
        data: { user },
      } = await this.supabaseClient.auth.getUser();
      console.log("supabase - getConnectedUser - user: ", user);
      return user;
    } catch (error) {
      console.error("Error fetching data: ", error);
      throw new Error(error);
    }
  }

  async getAllEvents() {
    try {
      const { data, error } = await this.getDataFromTable("events");
      if (error) {
        console.error("Error fetching data: ", error);
        throw new Error(error);
      }
      console.log("supabase - getAllEvents - data: ", data);
      return { data: data };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { error: error };
    }
  }

  async resendConfirmationEmail(email) {
    try {
      const { data, error } = await this.supabaseClient.auth.resend({
        type: "signup",
        email: email,
        options: {
          // emailRedirectTo: "https://example.com/welcome",
        },
      });
    } catch (error) {
      console.error("Error fetching data: ", error);
      throw new Error(error);
    }
  }
}

export default Supabase;
