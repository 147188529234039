import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { TextField, Button, Grid } from "@mui/material";
import Supabase from "../../models/Supabase";
import "add-to-calendar-button";
import { SupabaseClient } from "@supabase/supabase-js";
import { LoadingButton } from "@mui/lab";
export default function Confirm(props) {
  const supabase = props.supabase;
  const location = useLocation();
  const invitationId = location.pathname.split("/")[2];
  const [email, setEmail] = useState("");
  const [validParticipant, setValidParticipant] = useState(false);
  const [event, setEvent] = useState(null);
  const [participate, setParticipate] = useState(null);
  const [userId, setUserId] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [optInLoading, setOptInLoading] = useState(false);
  const [optOutLoading, setOptOutLoading] = useState(false);
  useEffect(() => {
    // getParticipantParticipate();
  }, []);

  const getEvent = async (eventId) => {
    const { data, error } = await supabase.getEvent(eventId);

    if (error) {
      console.error("Error fetching data: ", error);
      return;
    }

    setEvent(data);
    console.log("event: ", data);
  };

  const getParticipantParticipate = async (eventId, userId) => {
    console.log("getParticipantParticipate: ", eventId, userId);
    const { data, error } = await supabase.getParticipantParticipate(
      eventId,
      userId
    );
    if (error) {
      console.error("Error fetching data: ", error);
      return;
    }
    console.log("getUserParticipate: ", data);
    setParticipate(data);
  };

  const getUserIdFromEmail = async (email) => {
    const { data, error } = await supabase.getUserFromEmail(email);
    if (error) {
      console.error("Error fetching data: ", error);
      return;
    }
    console.log("getUserFromEmail: ", data);
    setUserId(data);
    return data;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { data, error } = await supabase.validateInvitation(
      invitationId,
      email
    );
    if (error) {
      console.error("Error fetching data: ", error);
      props.setToast("Invalid invitation", "error");
      return;
    }
    const eventId = data;
    if (!eventId) {
      console.error("Invalid invitation");
      props.setToast("Invalid invitation", "error");

      return;
    }
    console.log("eventId: ", eventId);
    getEvent(eventId);
    const userId = await getUserIdFromEmail(email);

    getParticipantParticipate(eventId, userId);
    props.setToast("Valid email", "success");
    setUserId(userId);
    setEmail(email);
    setEventId(eventId);
  };

  const optIn = async () => {
    setOptInLoading(true);
    console.log("optIn: ", eventId, userId);
    const { data, error } = await supabase.optInEvent(eventId, userId);
    if (error) {
      console.error("Error fetching data: ", error);
      setOptInLoading(false);

      return;
    }
    console.log("optIn: ", data);
    getParticipantParticipate(eventId, userId);
    setOptInLoading(false);
  };
  const optOut = async () => {
    setOptOutLoading(true);
    const { data, error } = await supabase.optOutEvent(eventId, userId);
    if (error) {
      console.error("Error fetching data: ", error);
      setOptOutLoading(false);

      return;
    }
    console.log("optOut: ", data);
    getParticipantParticipate(eventId, userId);
    setOptOutLoading(false);
  };

  return (
    <>
      {event ? (
        <>
          <h3>Are you in?</h3>
          <p>
            you are invited at {event.what} from {event.who} the{" "}
            {new Date(event.when).toLocaleDateString("fr-FR", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}{" "}
            at {event.where}
          </p>
          <p>We would be glad if you can join us in the celebration! </p>
          <p>Please let us know if you can make it</p>
          {participate ? (
            <p>You have accepted the invitation, see you soon!</p>
          ) : (
            <>
              {participate === null ? (
                <p>You have not responded yet</p>
              ) : (
                <p>
                  You have declined the invitation, you still can change your
                  mind!
                </p>
              )}
            </>
          )}
          <Grid
            container
            spacing={2}
            //   center content vertically and horizontally
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={optIn}
                disabled={participate || optInLoading}
                loading={optInLoading}
                loadingPosition="start"
                // set width to 150 px
                style={{ width: "150px" }}
              >
                I'm in!
              </LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                color="error"
                onClick={optOut}
                disabled={
                  (participate !== null && !participate) || optOutLoading
                }
                loading={optOutLoading}
                loadingPosition="start"
                style={{ width: "150px" }}
              >
                I can't
              </LoadingButton>
            </Grid>
          </Grid>
          <br />
          <Grid
            container
            spacing={2}
            //   center content vertically and horizontally
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <add-to-calendar-button
                name={event.what + event.who}
                startDate={event.when}
                options="['Apple','Google','iCal','Microsoft365','Outlook.com','Yahoo']"
                timeZone="Europe/Brussels"
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <h3>Fill in your email:</h3>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              // set direction to column
              direction="column"
            >
              <Grid item>
                <TextField
                  type="email"
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </>
  );
}
