import React, { useState } from "react";
import { TextField, Button, Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Supabase from "../../models/Supabase";
// const useStyles = makeStyles((theme) => ({
//   form: {
//     display: "flex",
//     flexDirection: "column",
//     gap: theme.spacing(2),
//   },
//   submitButton: {
//     marginTop: theme.spacing(2),
//   },
// }));

const AddEventForm = (props) => {
  // const classes = useStyles();
  const supabase = props.supabase;
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [participants, setParticipants] = useState("");
  const [deadline, setDeadline] = useState("");
  const [date, setDate] = useState("");
  const imageInputRef = React.createRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your logic to handle form submission here
    supabase.insertEvent({ name: name, when: deadline });
  };

  return (
    <>
      <h3 style={{ marginTop: 0 }}>Add events</h3>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          {/* <Grid item xs={12}>
          <TextField
            value={image}
            // onChange={(e) => setImage(e.target.value)}
            InputProps={{
              type: "file",

              inputRef: imageInputRef,
              onChange: (e) => {
                const file = e.target.files[0];
                // only allows images .jpg, .jpeg, .png
                if (
                  file.type === "image/jpeg" ||
                  file.type === "image/jpg" ||
                  file.type === "image/png"
                ) {
                  setImage(file);
                } else {
                  alert("Please select an image file");
                }
              },
            }}
          />
        </Grid> */}
          {/* <Grid item xs={12}>
          <TextField
            label="Participants"
            value={participants}
            onChange={(e) => setParticipants(e.target.value)}
          />
        </Grid> */}
          <Grid item xs={12}>
            {/* <DatePicker label="Basic date picker" /> */}
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Deadline"
              value={deadline}
              onChange={(e) => setDeadline(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              Add Event
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddEventForm;
