import React, { useEffect, useState } from "react";
import CustomCard from "../../components/customCard";
import { Fab, Grid, Modal } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import AddEventForm from "./AddEventForm";
import Supabase from "../../models/Supabase";
import { Button } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};

function Home(props) {
  const supabase = props.supabase;
  const [events, setEvents] = useState([]);
  const [user, setUser] = useState(props.user);
  useEffect(() => {
    getAllEvents();
  }, []);

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  const getAllEvents = async () => {
    const { data, error } = await supabase.getAllEvents();
    const events = data;
    console.log("home - events: ", events);
    setEvents(events);
  };

  const handleAddEvent = () => {
    // Add event logic here
    setOpen(true);
  };
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const getCurrentUser = async () => {
    const user = await supabase.getConnectedUser();
    console.log("home - user: ", user);
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AddEventForm />
        </Box>
      </Modal>
      {/* <Button onClick={getCurrentUser}>Get User</Button> */}
      <br />
      {/* <Button onClick={getAllEvents}>Get Events</Button> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "80%",
          margin: "auto",
        }}
      >
        <Grid container spacing={2}>
          {events.map((event) => (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomCard
                key={event.id}
                id={event.id}
                image={event.image}
                name={event.name}
                date={event.date}
              />
            </Grid>
          ))}
        </Grid>
        <Fab
          className="sticky-icon-button"
          color="primary"
          onClick={handleAddEvent}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
          }}
        >
          <AddIcon />
        </Fab>
      </Box>
    </div>
  );
}

export default Home;
