import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Supabase from "../../models/Supabase";
import ParticipantsList from "./ParticipantsList";
import AddParticipant from "./AddParticipant";
import { CircularProgress } from "@mui/material";
const ParticipantsListPage = (props) => {
  // get the eventId from the URL
  const location = useLocation();

  const supabase = props.supabase;
  const [event, setEvent] = useState(null);
  const [eventParticipants, setEventParticipants] = useState(null);
  // Find the event with the matching eventId
  useEffect(() => {
    // get the eventId from the URL
    const eventId = location.pathname.split("/")[2];
    if (eventId === undefined) return;
    getEvent(eventId);
    getEventParticipants(eventId);
  }, [location]);

  const getEvent = async (eventId) => {
    console.log("home - getEvent: ", eventId);
    const { data, error } = await supabase.getEvent(eventId);
    if (error) {
      console.error("Error fetching data: ", error);
      return;
    }

    console.log("home - events: ", data);
    setEvent(data);
  };

  const getEventParticipants = async (eventId) => {
    console.log("home - getEventParticipants: ", eventId);
    const { data, error } = await supabase.getEventParticipants(eventId);
    if (error) {
      console.error("Error fetching data: ", error);
      return;
    }
    setEventParticipants(data);
    console.log("home - getEventParticipants: ", data);
  };

  const addParticipantToEvent = (participant) => {
    setEventParticipants([...eventParticipants, participant]);
  };

  return (
    <div>
      <h1>Event Details</h1>
      {event ? (
        <div>
          <h2>{event.name}</h2>
          <p>Date: {event.when}</p>
        </div>
      ) : (
        <div>
          <h2>
            <CircularProgress size={28} />
          </h2>
          <p>
            Date: <CircularProgress size={20} />
          </p>
        </div>
      )}
      <h2>Participants</h2>
      <AddParticipant
        eventId={event?.id}
        addParticipantToEvent={(participant) =>
          addParticipantToEvent({ users: participant })
        }
        supabase={supabase}
      />
      <br />
      <ParticipantsList
        participants={eventParticipants}
        setToast={props.setToast}
        getEventParticipants={getEventParticipants}
        supabase={supabase}
      />
    </div>
  );
};

export default ParticipantsListPage;
