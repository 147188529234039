import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import EmailIcon from "@mui/icons-material/Email";
import SignUpChoice from "./SignUpChoice";
import EmailSignup from "./EmailSignup";
import GoogleSignUp from "./GoogleSignUp";
function SignUp() {
  const location = useLocation();
  const [signUpType, setSignUpType] = useState(null);
  useEffect(() => {
    console.log(location);
    const searchParams = new URLSearchParams(location.search);
    const signUpType = searchParams.get("type");
    console.log(signUpType);
    setSignUpType(signUpType);
  }, [location]);

  return (
    <>
      {signUpType === "google" ? (
        <GoogleSignUp />
      ) : signUpType === "email" ? (
        <EmailSignup type="email" />
      ) : (
        <SignUpChoice />
      )}
    </>
  );
}

export default SignUp;
