import React from "react";
import Supabase from "../../models/Supabase";
import { Button } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";

const GoogleSignUp = (props) => {
  const supabase = props.supabase;
  const handleSignIn = async () => {
    const { error } = await supabase.auth.signIn({
      provider: "google",
    });

    if (error) console.error("Error signing in:", error);
  };

  return (
    <div>
      <Button onClick={handleSignIn} {...props}>
        Google
      </Button>
    </div>
  );
};

export default GoogleSignUp;
