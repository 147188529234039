import React, { useEffect, useState } from "react";

import logo from "./logo.svg";
import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/home";
import SuccessLogin from "./pages/SuccessLogin/SuccessLogin";
import SignUp from "./pages/Signup/SignUp";
import ParticipantList from "./pages/ParticipantList/ParticipantListPage";
import Confirm from "./pages/Confirm/Confirm";
import Supabase from "./models/Supabase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/EventDashboard/Dashboard.jsx";
function App() {
  const toastId = React.useRef(null);
  const supabase = new Supabase();
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    console.log("user: ", user);
    if (user === null && window.location.pathname !== "/login") {
      // move to login page
      // window.location.href = "/login";
    }
  }, [user]);

  const logout = () => setUser(null);
  const getUserInfo = async () => {
    const user = await supabase.getConnectedUser();
    setUser(user);
  };

  const setToast = (message, type, icon = null, _toastId = null) => {
    console.log("updating toastid: ", _toastId);
    if (_toastId) {
      toast.update(_toastId, {
        type: type,
        icon: icon,
        render: message,
      });
      return _toastId;
    }
    toastId.current = toast(message, { type: type, icon: icon });
    return toastId.current;
  };

  return (
    <div className="App">
      <ToastContainer pauseOnFocusLoss={false} position="bottom-right" />
      <BrowserRouter>
        <Navbar user={user} logout={logout} supabase={supabase} />

        <Routes>
          <Route
            path="/login"
            element={
              <Login
                setUser={setUser}
                setToast={setToast}
                supabase={supabase}
              />
            }
          />
          <Route
            path="/event/:id/participants"
            element={
              <ParticipantList setToast={setToast} supabase={supabase} />
            }
          />
          <Route
            path="/event/:id"
            element={<Dashboard setToast={setToast} supabase={supabase} />}
          />

          <Route
            path="/signup"
            element={<SignUp setToast={setToast} supabase={supabase} />}
          />
          <Route
            path="/successLogin"
            element={<SuccessLogin setToast={setToast} supabase={supabase} />}
          />
          <Route
            path="/"
            element={
              user === null ? (
                <Login
                  setUser={setUser}
                  setToast={setToast}
                  supabase={supabase}
                />
              ) : (
                <Home supabase={supabase} />
              )
            }
          />
          <Route
            path="/confirm/:invitation_id"
            element={<Confirm setToast={setToast} supabase={supabase} />}
            setToast={setToast}
          />
          <Route path="*" element={<h1>Not Found</h1>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
