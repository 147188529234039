import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Grid,
  IconButton,
} from "@mui/material";
import Supabase from "../models/Supabase";
import HomeIcon from "@mui/icons-material/Home";
const Navbar = (props) => {
  const navigate = useNavigate();
  const supabase = props.supabase;
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      setUser(JSON.parse(localStorage.getItem("user")));
    }
  }, [props]);

  const logout = () => {
    const { data, error } = supabase.logout();
    if (error) {
      console.error("Error logging out:", error);
      return;
    }
    console.log("User logged out");
    localStorage.removeItem("user");
    setUser(null);
    props.logout();
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6">Invite.s.io</Typography>
        {user ? (
          <Grid container spacing={2}>
            <Grid item>
              <IconButton
                style={{
                  marginLeft: "15px",
                }}
                // color="secondary"
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/");
                }}
                sx={{ color: "black" }}
              >
                {/* set home icon */}
                <HomeIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              // place element to the right
              style={{
                marginLeft: "auto",
              }}
            >
              <Button
                style={{
                  marginLeft: "auto",
                }}
                color="secondary"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  logout();
                }}
              >
                Logout
              </Button>
            </Grid>
          </Grid>
        ) : (
          <>
            Sign Up
            <Button
              style={{
                marginLeft: "auto",
              }}
              color="secondary"
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                navigate("/signup");
              }}
            >
              Sign Up
            </Button>
            <Button
              style={{
                marginLeft: "10px",
              }}
              color="secondary"
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                navigate("/login");
              }}
            >
              Log in
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
