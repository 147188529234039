import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Supabase from "../../models/Supabase";
import { Grid, Icon, LinearProgress } from "@mui/material";

import DashboardCard from "./DashBoardCard";
export default function Dashboard(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const supabase = props.supabase;
  const [event, setEvent] = useState(null);
  const [total, setTotal] = useState(0);
  const [accepted, setAccepted] = useState(0);
  const [refused, setRefused] = useState(0);
  const [eventParticipants, setEventParticipants] = useState(null);
  // Find the event with the matching eventId
  useEffect(() => {
    // get the eventId from the URL
    const eventId = location.pathname.split("/")[2];
    if (eventId === undefined) return;
    getEvent(eventId);
    getEventParticipants(eventId);
  }, [location]);

  const getEvent = async (eventId) => {
    console.log("home - getEvent: ", eventId);
    const { data, error } = await supabase.getEvent(eventId);
    if (error) {
      console.error("Error fetching data: ", error);
      return;
    }

    console.log("home - events: ", data);
    setEvent(data);
  };

  const getEventParticipants = async (eventId) => {
    console.log("home - getEventParticipants: ", eventId);
    const { data, error } = await supabase.getEventParticipants(eventId);
    if (error) {
      console.error("Error fetching data: ", error);
      return;
    }
    console.log("home - getEventParticipants: ", data);
    // count number of participants
    let total = data.length;
    console.log("total: ", total);
    data.total = total;
    // count number of particpants that accepted
    let accepted = data.filter(
      (participant) => participant.participate === true
    ).length;
    console.log("accepted: ", accepted);
    data.accepted = accepted;
    // count number of particpants that refused
    let refused = data.filter(
      (participant) => participant.participate === false
    ).length;
    console.log("refused: ", refused);
    data.refused = refused;
    console.log("home - getEventParticipants: ", data);
    setEventParticipants(data);
  };

  return (
    <div>
      <h2>Event info</h2>
      {event && eventParticipants ? (
        <div>
          <h3>{event.name}</h3>
          <p>Date: {event.when}</p>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DashboardCard
                title="Participants"
                icon="👥"
                updateData={() => getEventParticipants(event.id)}
                accepted={eventParticipants.accepted}
                refused={eventParticipants.refused}
                total={eventParticipants.total}
                url="participants"
              />
            </Grid>
            <Grid item xs={12}>
              <DashboardCard
                title="Venues"
                icon="🏟️"
                updateData={() => getEventParticipants(event.id)}
                accepted={eventParticipants.accepted}
                refused={eventParticipants.refused}
                total={eventParticipants.total}
                url="participants"
              />
            </Grid>
          </Grid>
        </div>
      ) : (
        <div>
          <h3>Loading...</h3>
        </div>
      )}
    </div>
  );
}
