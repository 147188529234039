import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import EmailIcon from "@mui/icons-material/Email";
import GoogleSignUp from "./GoogleSignUp";
export default function SignUpChoice() {
  return (
    <div>
      <h2>Sign Up</h2>
      <p>Please choose your signup method:</p>
      <Grid
        container
        spacing={2}
        direction="column"
        style={{
          width: "200px",
          margin: "0 auto",
        }}
      >
        <Grid item>
          <Link to="/signup?type=email">
            <Button variant="contained" startIcon={<EmailIcon />} fullWidth>
              Email
            </Button>
          </Link>
        </Grid>
        <Grid item>
          <GoogleSignUp
            variant="contained"
            fullWidth
            startIcon={<GoogleIcon />}
            disabled
          />
        </Grid>
      </Grid>
    </div>
  );
}
