import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Icon } from "@mui/material";
import { Check, CheckCircleOutline, Clear, Delete } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import Supabase from "../../models/Supabase";
import PyServer from "../../models/PyServer";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { GridToolbar } from "@mui/x-data-grid";

export default function ParticipantsList(props) {
  const location = useLocation();
  const supabase = props.supabase;
  const pyserver = new PyServer();
  const [toastId, setToastId] = useState(null);
  const [participants, setParticipants] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [sendingInvite, setSendingInvite] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);

  useEffect(() => {
    console.log("_toastId: ", toastId);
  }, [toastId]);

  const columns = [
    { field: "id", headerName: "id", width: 100 },
    {
      field: "firstName",
      headerName: "first name",
      width: 250,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
    },

    {
      field: "email_sent",
      headerName: "Email sent",
      width: 100,
      renderCell: (params) => {
        return (
          <div
            // center content vertically and horizontally
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {params.value ? (
              // check icon
              <Check color="success" />
            ) : (
              ""
            )}
          </div>
        );
      },
    },

    {
      field: "email_read",
      headerName: "Email read",
      width: 100,
      renderCell: (params) => {
        return (
          <div
            // center content vertically and horizontally
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {params.value ? (
              // check icon
              <Check color="success" />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      field: "participate",
      headerName: "Participate",
      width: 100,
      renderCell: (params) => {
        console.log("participate", params);
        return (
          <div
            // center content vertically and horizontally
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {params.value ? (
              // check icon
              <Check color="success" />
            ) : (
              <>{params.value === false ? <Clear color="error" /> : ""}</>
            )}
          </div>
        );
      },
    },
    {
      field: "admin",
      headerName: "Admin",
      width: 50,
      renderCell: (params) => {
        return (
          <div
            // center content vertically and horizontally
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {params.value ? (
              // check icon
              <Check color="success" />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    // add button to delete participant
    {
      field: "send invite",
      headerName: "Send invite",
      width: 100,
      renderCell: (params) => {
        let toastId = null;
        const setInvite = async (userId, enventId) => {
          // add your logic to send invite here
          toastId = props.setToast("Sending invite", "pending");
          setToastId(toastId);
          try {
            setSendingInvite(true);
            const { data, error } = await supabase.setInvite(userId, eventId);
            if (error) {
              console.log("error: ", error);
              return;
            }
            console.log("result: ", data);
            await triggerInvites(eventId, userId);
            setSendingInvite(false);
          } catch (err) {
            console.log(err);
            setSendingInvite(false);
          }
          await setEmailSent(userId, eventId);
          setSendingInvite(false);
        };

        const triggerInvites = async (eventId, userId) => {
          // add your logic to trigger invites here
          try {
            const { data, error } = await pyserver.sendInvite(eventId, userId);
            if (error) {
              console.log("error: ", error);
              props.setToast("Invite failed", "error", "", toastId);

              return;
            }
            console.log("result: ", data);
            props.setToast("Invite sent", "success", "", toastId);
          } catch (err) {
            console.log(err);
            props.setToast("Invite failed", "error", "", toastId);
          }
        };
        return (
          <div
            // center content vertically and horizontally
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <LoadingButton
              style={{
                backgroundColor: "#36AE7C",
                color: "white",
                border: "none",
                padding: "5px 10px",
                borderRadius: "5px",
              }}
              loading={sendingInvite}
              disabled={sendingInvite}
              loadingPosition="start"
              loadingIndicator=""
              onClick={() => {
                setInvite(params.id, eventId);
              }}
            >
              <SendIcon />
            </LoadingButton>
          </div>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 100,
      renderCell: (params) => {
        return (
          <div
            // center content vertically and horizontally
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <IconButton
              style={{
                backgroundColor: "lightcoral",
                color: "white",
                border: "none",
                padding: "5px 10px",
                borderRadius: "5px",
              }}
              onClick={() => {
                console.log("delete participant: ", params.row.id);
                deleteParticipant(params.row.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    // get the eventId from the URL
    const eventId = location.pathname.split("/")[2];
    setEventId(eventId);
    if (eventId === undefined) return;
  }, [location]);

  useEffect(() => {
    if (props.participants === undefined || props.participants === null) return;
    setParticipants(
      props.participants.map((participant) => {
        return {
          id: participant.users.id,
          email: participant.users.email,
          firstName: participant.users.first_name,
          admin: participant.admin,
          email_sent: participant.email_sent,
          email_read: participant.email_read,
          participate: participant.participate,
        };
      })
    );
  }, [props]);

  const deleteParticipant = async (userId) => {
    // add your logic to delete participant here
    try {
      const toastId = props.setToast("Deleting participant", "pending");
      console.log("toastId: ", toastId);
      setToastId(toastId);
      const { data, error } = await supabase.disableParticipant(userId);
      if (error) {
        console.log("error: ", error);
        props.setToast("Error deleting participant", "error", "", toastId);
        return;
      }
      console.log("result: ", data);
      const newParticipants = participants.filter((participant) => {
        return participant.id !== userId;
      });
      setParticipants(newParticipants);
      props.setToast("Participant deleted", "success", "", toastId);
    } catch (err) {
      console.log(err);
      props.setToast("Error deleting participant", "error", "", toastId);
    }
  };

  const setEmailSent = async (userId, eventId) => {
    try {
      const { data, error } = await supabase.setEmailSent(userId, eventId);
      if (error) {
        console.log("error: ", error);
        return;
      }
      console.log("result: ", data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "80%",
          // center the box
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        <DataGrid
          // enable export

          slots={{
            toolbar: GridToolbar,
            loadingOverlay: LinearProgress,
            footer: () => (
              <LoadingButton
                style={{
                  backgroundColor: "#36AE7C",
                  color: "white",
                  border: "none",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
                fullWidth
                // loading={refreshLoading}
                // disabled={refreshLoading}
                onClick={async () => {
                  setRefreshLoading(true);
                  await props.getEventParticipants(eventId);
                  setRefreshLoading(false);
                }}
              >
                refresh
                <RefreshIcon />
              </LoadingButton>
            ),
          }}
          rows={participants ? participants : []}
          columns={columns}
          pageSize={5}
          loading={participants === null}
          // add icon button on bottom to refresh the list
        />
      </Box>
    </div>
  );
}
