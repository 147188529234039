import React, { useEffect, useState } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const CustomCard = (props) => {
  const navigate = useNavigate();
  const { name, date, image, id } = props;
  const [displayImage, setDisplayImage] = useState("");

  useEffect(() => {
    const { name, date, image, id } = props;
    if (!image) return;
    setDisplayImage(require(`../images/${image}.png`));
    console.log("CustomCard - props: ", props);
    console.log("CustomCard - displayImage: ", displayImage);
  }, [props]);

  return (
    <Card>
      <CardActionArea
        // make it clickable
        onClick={() => {
          navigate(`/event/${id}`);
        }}
      >
        <CardMedia
          component="img"
          height="140"
          image={displayImage}
          alt={name}
        />
        <CardContent>
          <Typography variant="h5" component="div">
            {name}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {date}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CustomCard;
