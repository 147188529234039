import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, LinearProgress } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";

export default function DashBoardCard(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const url = props.url;
  const title = props.title;
  const icon = props.icon;
  const [accepted, setAccepted] = useState(props.accepted);
  const [refused, setRefused] = useState(props.refused);
  const [total, setTotal] = useState(props.total);

  useEffect(() => {
    setAccepted(props.accepted);
    setRefused(props.refused);
    setTotal(props.total);
  }, [props]);
  return (
    <Box
      //   set width to 80% of the parent container
      width="80%"
      // center the progress bar
      margin="auto"
      //place content in the center of the box
      justifyContent={"center"}
      alignContent={"center"}
      // add a shadow to the box
      boxShadow={0}
      // add thin rounded border
      border={1}
      borderRadius={5}
      height={110}
      // make the color of the border lighter
      borderColor="lightgrey"
    >
      <Box
        width="90%"
        margin="auto"
        onClick={(e) => {
          e.preventDefault();
          // navigate to the participants page using navigate adding /participants to the current location
          navigate(location.pathname + "/" + url);
        }}
        //   hover should have the hand cursor
        sx={{ cursor: "pointer" }}
      >
        <Grid container spacing={2}>
          <Grid item>
            <h3>
              <span role="img" aria-label="check">
                {icon}
              </span>
              {title}
            </h3>
          </Grid>

          <Grid item>
            <h4>
              <span role="img" aria-label="check">
                ✔️
              </span>
              {accepted}
            </h4>
          </Grid>
          <Grid item>
            <h4>
              <span role="img" aria-label="cross">
                ❌
              </span>
              {refused}
            </h4>
          </Grid>
          <Grid item>
            <h4>
              <span role="img" aria-label="question">
                ⏳
              </span>
              {total - accepted - refused}
            </h4>
          </Grid>
          <Grid item xs textAlign="right">
            <h3>
              Total: {total}{" "}
              {/* <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  props.updateData();
                }}
              >
                <RefreshIcon />
              </IconButton> */}
            </h3>
          </Grid>
        </Grid>
        <LinearProgress
          variant="buffer"
          value={(accepted * 100) / total}
          valueBuffer={((refused + accepted) * 100) / total}
          thickness={4}
          sx={{
            "& .MuiLinearProgress-dashed": {
              backgroundColor: "lightgrey",
              backgroundImage: "none",
              animation: "none",
            },

            // set the color of the value bar
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          }}
        />
      </Box>
    </Box>
  );
}
