import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Grid } from "@mui/material";
import Supabase from "../../models/Supabase";
const Login = (props) => {
  const navigate = useNavigate();
  const supabase = props.supabase;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    // Add your login logic here
    try {
      const { data, error } = await supabase.login(email, password);
      if (error) {
        if (error.message.endsWith("mail not confirmed")) {
          setError("Please confirm your email before logging in.");
          console.error("Error logging in:", error);
        }
      }
      const user = data.user;
      const session = data.session;
      props.setUser(user);
      // Save user to local storage
      localStorage.setItem("user", JSON.stringify(user));
      console.log("User logged in:", user);
      console.log("Session:", session);
      navigate("/");
    } catch (error) {
      console.error("Error logging in:", error);
      console.error("Errormessage logging in:", error.message);
      props.setToast("email or password is incorrect");
    }
  };

  return (
    <div className="container" style={{ padding: "50px 0 100px 0" }}>
      <div>
        <h1>Login</h1>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            direction="column"
            spacing={2}
            style={{
              width: "200px",
              // center the content
              margin: "0 auto",
            }}
          >
            <Grid item>
              <TextField
                label="Email"
                type="email"
                value={email}
                onChange={handleEmailChange}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
              />
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
    </div>
  );
};

export default Login;
